
class WebNFC80Driver extends Driver {
  constructor() {
    super("webnfc80");
  }

  get supported() {
    return window && 'NDEFReader' in window;
  }
  get options() {
    return {
      polling: true
    }
  }

  setup(readCallback) {
    if(this.supported) {
      this.reader = new NDEFReader();
      this.reader.onreading = (event) => {
        var record = event.message.records[0]
        var text = new TextDecoder("utf-8").decode(record.data.buffer);
        console.debug("NFC read: ", text)
        readCallback(text, null);
      }
      this.reader.scan();
      console.debug("NFC read listener added")
    }
  }
  write(text, writeCallback) {
    if(this.supported) {
      if(this.pendingWrite && this.writeAbortController) {
        this.writeAbortController.abort();
      }
      this.pendingWrite = true;
      this.writer = new NDEFWriter();
      this.writeAbortController = new AbortController();

      var write = this.writer.push;
      if(!write) write = this.writer.write; // WebNFC - Chrome 81
      write.call(this.writer, {
        records: [{ recordType: "text", data: text }]
      }, {
        signal: this.writeAbortController.signal, 
        overwrite: true
      })
      .then(() => {
        console.debug("NFC push done");
        this.pendingWrite = false;
        writeCallback(text, null);
      })
      .catch(err => {
        if(err.code == 20 && err.name == "AbortError") {
          console.debug("NFC push cancelled");
        } else {
          console.error(err);
          writeCallback(null, err);
        }
        this.pendingWrite = false;
      });
    }
  }
  cancelWrite(cancelCallback) {
    if(this.pendingWrite && this.writeAbortController) {
      this.writeAbortController.abort();
    }
    this.pendingWrite = false;
    cancelCallback(null);
  }
}

class WebNFC79Driver extends Driver {
  constructor() {
    super("webnfc79");
  }

  get supported() {
    return window && 'NFCReader' in window;
  }
  get options() {
    return {
      polling: true
    }
  }

  setup(readCallback) {
    if(this.supported) {
      this.reader = new NFCReader();
      this.reader.onreading = (event) => {
        var record = event.message.records[0]
        var text;
        if("text" in record) {
          text = record.text()
        } else if("toText" in record) {
          text = record.toText()
        }
        console.debug("NFC read: ", text)
        readCallback(text, null);
      }
      this.reader.scan();
      console.debug("NFC read listener added")
    }
  }
  write(text, writeCallback) {
    if(this.supported) {
      if(this.pendingWrite && this.writeAbortController) {
        this.writeAbortController.abort();
      }
      this.pendingWrite = true;
      this.writer = new NFCWriter();
      this.writeAbortController = new AbortController();
      this.writer.push({
        records: [{ recordType: "text", data: text }]
      }, { signal: this.writeAbortController.signal, overwrite: true })
      .then(() => {
        console.debug("NFC push done");
        this.pendingWrite = false;
        writeCallback(text, null);
      })
      .catch(err => {
        if(err.code == 20 && err.name == "AbortError") {
          console.debug("NFC push cancelled");
        } else {
          console.error(err);
          writeCallback(null, err);
        }
        this.pendingWrite = false;
      });
    }
  }
  cancelWrite(cancelCallback) {
    if(this.pendingWrite && this.writeAbortController) {
      this.writeAbortController.abort();
    }
    this.pendingWrite = false;
    cancelCallback(null);
  }
}

class WebNFC76Driver extends Driver {
  constructor() {
    super("webnfc76");
  }

  get supported() {
    return navigator && 'nfc' in navigator;
  }
  get options() {
    return {
      polling: true
    }
  }

  setup(readCallback) {
    if(this.supported) {
      navigator.nfc.watch((message) => {
        var text = message.records[0].data;
        if(typeof message.records[0].data === "function") text = text();
        console.debug("NFC read: ", text)
        readCallback(text, null);
      }, {mode:"any"})
        .then(() => {
          console.debug("NFC read listener added")
        })
        .catch(err => {
          console.error(err);
          readCallback(null, err);
        });
    }
  }
  write(text, writeCallback) {
    if(this.supported) {
      var message = {
        records: [{
          recordType: "text",
          mediaType: "text/plain",
          data: '\n'+text
        }],
        target: "tag",
        ignoreRead: true
      };

      navigator.nfc.push(message).then(() => {
        console.debug("NFC write done");
        writeCallback(text, null);
      }).catch((error) => {
        console.debug("NFC write failed", error);
        writeCallback(null, error);
      });
    }
  }
  cancelWrite(cancelCallback) {
    navigator.nfc.cancelPush().then(() => {
      console.debug("NFC write cancelled");
      cancelCallback(null);
    }).catch((error) => {
      console.debug("NFC write cancellation failed", error);
      cancelCallback(error);
    });
  }
}
