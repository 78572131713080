class WVNFCDriver extends Driver {
  constructor() {
    super("wvnfc");
  }

  get supported() {
    return typeof wvnfcVersion !== 'undefined';
  }
  get options() {
    return {
      polling: false
    }
  }

  setup(readCallback) {
    if(this.supported) {
      wvnfcVersion().then((version) => {
        NFC.log("WVNFC: version =", version);
      });
      this.readCallback = readCallback;
    }
  }
  requestRead() {
    var self = this;
    wvnfcRead().then((text) => {
      self.readCallback(text, null);
    }).catch((error) => {
      self.readCallback(null, error);
    });
  }
  write(text, writeCallback) {
    wvnfcWrite(text).then((written) => {
      writeCallback(written, null);
    }).catch((error) => {
      writeCallback(null, error);
    });
  }
  cancelWrite(cancelCallback) {
    wvnfcCancel().then(() => {
      cancelCallback(null);
    }).catch((error) => {
      cancelCallback(error);
    })
  }
}
