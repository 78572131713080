class AndroidDriver extends Driver {
  constructor() {
    super("android");
  }

  get supported() {
    return typeof elsysNfc !== "undefined";
  }
  get options() {
    return {
      polling: true
    }
  }

  setup(readCallback) {
    if(this.supported) {
      elsysNfc.callbacks = {};
      elsysNfc.callbacks['1'] = (message) => {
        var text = message.records[0].data;
        console.debug("NFC read: ", text)
        try { readCallback(text, null); }
        catch { return false; }
        return true;
      };
    }
  }
  write(text, writeCallback) {
    elsysNfc.callbacks['2'] = (result) => {
      var text = result.text;
      console.debug("NFC write: ", text)
      try { writeCallback(text, null); }
      catch { return false; }
      return true;
    };
    elsysNfc.write(text);
  }
  cancelWrite(cancelCallback) {
    elsysNfc.callbacks['3'] = (result) => {
      console.debug("NFC write cancelled")
      try { cancelCallback(result.error); }
      catch { return false; }
      return true;
    };
    elsysNfc.cancelWrite();
  }
}
