class MockDriver extends Driver {
  constructor() {
    super("mock");
  }

  get supported() {
    return true;
  }
  get options() {
    return {
      polling: true
    }
  }

  setup(readCallback) {
    if(this.supported) {
      this.readCallback = readCallback;
    }
  }
  write(text, writeCallback) {
  }
  cancelWrite(cancelCallback) {
    setTimeout(() => {
      cancelCallback(null);
    }, 100);
  }
}
