class NFC {
  constructor() {
    this.drivers = [];
    //this.drivers.push(new ChromeExtDriver());
    //this.drivers.push(new ElectronDriver());
    this.drivers.push(new AndroidDriver());
    this.drivers.push(new IOSDriver());
    this.drivers.push(new WVNFCDriver());
    this.drivers.push(new WSNFCDriver());
    this.drivers.push(new WebNFC80Driver());
    this.drivers.push(new WebNFC79Driver());
    this.drivers.push(new WebNFC76Driver());
    //this.drivers.push(new MockDriver());
  }

  get driver() {
    return this.drivers.find((driver) => {
      return driver.supported;
    });
  }

  static log() {
    var args = Array.from(arguments);
    args.unshift("border: 1px solid black; background: lightgreen; padding: 0px 2px; border-radius: 2px;");
    args.unshift("%cNFC");
    console.debug.apply(this, args);
  }

  static use(f) {
    var nfc = new NFC();
    var tries = 0;
    var init = () => {
      if(nfc.driver != null) {
        NFC.log("Active NFC driver", nfc.driver);
        f(nfc.driver);
      } else {
        if(tries++ < 3) {
          setTimeout(init, 1000);
          NFC.log("No NFC driver, retrying in 1000 ms");
        } else {
          NFC.log("No NFC driver, giving up after 3 retries");
          f(new MockDriver());
        }
      }
    };
    init();
  }
}

class Driver {
  constructor(name) {
    this.name = name;
  }

  get supported() {}
  get options() {}

  setup(readCallback) {}
  requestRead() {}
  write(text, writeCallback) {}
  cancelWrite(cancelCallback) {}
}
