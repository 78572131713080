class IOSDriver extends Driver {
  constructor() {
    super("ios");
  }

  get supported() {
    return (/iPad|iPhone|iPod/.test(navigator.platform) ||
           (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
           !window.MSStream;
  }
  get options() {
    return {
      polling: false
    }
  }

  setup(readCallback) {
    if(this.supported) {
      window.elsysIosNfc = {};
      elsysIosNfc.callbacks = {};
      elsysIosNfc.callbacks['1'] = (text) => {
        if(text.startsWith("\u0002en")) text = text.substring(3);
        console.debug("NFC read: ", text)
        readCallback(text, null);
      };
    }
  }
  requestRead() {
    webkit.messageHandlers.requestRead.postMessage("requestRead");
    // evaluateJavaScript("elsysNfc.callbacks['1'](" + tagText + ")");
  }
  write(text, writeCallback) {
    elsysIosNfc.callbacks['2'] = (text) => {
      console.debug("NFC write: ", text)
      writeCallback(text, null);
    };
    webkit.messageHandlers.requestWrite.postMessage(text);
  }
  cancelWrite(cancelCallback) {
    elsysIosNfc.callbacks['3'] = (result) => {
      console.debug("NFC write cancelled")
      cancelCallback(result.error);
    };
    webkit.messageHandlers.requestCancel.postMessage("requestCancel");
  }
}
