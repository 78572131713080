class WSNFCDriver extends Driver {
  constructor() {
    super("wsnfc");
    this.tryOpenSocket();
  }

  get supported() {
    return this.socket !== null;
  }
  get options() {
    return {
      polling: false
    }
  }

  tryOpenSocket() {
    if(this.openedOnce) return;
    this.openedOnce = true;

    this.openSocket();
  }

  openSocket(f) {
    this.socket = null;
    this.clientId = "wsnfc"+Math.random();
    NFC.log("WSNFC: socket connecting (client_id="+this.clientId+")");
    var socket = new WebSocket("ws://127.0.0.1:6439/wsnfc");
    socket.onerror = (event) => {
      this.socket = null;
    };
    socket.onopen = (event) => {
      NFC.log("WSNFC: socket open");
      this.socket = socket;
      if(f) f();
    };
    socket.onclose = (event) => {
      NFC.log("WSNFC: socket closed");
    };
    socket.onmessage = (event) => {
      NFC.log("WSNFC: received message =", event.data);
      this.handleMessage(event.data);
    };
  }

  assertSocket(f) {
    if(this.socket != null && this.socket.readyState == WebSocket.OPEN) {
      NFC.log("WSNFC: socket still alive");
      f();
    }
    if(this.socket == null
      || this.socket.readyState == WebSocket.CLOSED
      || this.socket.readyState == WebSocket.CLOSING) {
        NFC.log("WSNFC: socket invalid");
      this.openSocket(f);
    }
  }

  handleMessage(msg) {
    msg = JSON.parse(msg);
    if(msg.requestId !== undefined && this.clientId !== msg.requestId) {
      NFC.log("WSNFC: not processing message for client " + msg.requestId);
      return;
    }
    switch(msg.action) {
      case "readResult":
        this.readCallback(msg.data, msg.error ? msg.error : null);
        break;
      case "writeResult":
        this.writeCallback(msg.data, msg.error ? msg.error : null);
        break;
      case "cancelResult":
        this.cancelCallback(msg.error ? msg.error : null);
        break;
    }
  }

  sendMessage(msg) {
    NFC.log("WSNFC: sending message", msg);
    this.socket.send(JSON.stringify(msg));
  }

  setup(readCallback) {
    if(this.supported) {
      this.readCallback = readCallback;
    }
  }
  requestRead() {
    this.assertSocket(() => {
      this.sendMessage({
        action: "requestRead",
        requestId: this.clientId,
      });
    });
  }
  write(text, writeCallback) {
    this.assertSocket(() => {
      this.writeCallback = writeCallback;
      this.sendMessage({
        action: "write",
        data: text,
        requestId: this.clientId,
      });
    });
  }
  cancelWrite(cancelCallback) {
    this.assertSocket(() => {
      this.cancelCallback = cancelCallback;
      this.sendMessage({
        action: "cancelWrite",
        requestId: this.clientId,
      });
    });
  }
}
